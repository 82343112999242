import _ from 'lodash';

export const LOGIN_MUTATION = 'loginMutation';
export const LOGOUT_MUTATION = 'logoutMutation';
export const USER_MUTATION = 'userMutation';
export const ACCOUNT_FIELDS_MUTATION = 'accountFieldsMutation';
export const ACCOUNT_DOCUMENT_FIELDS_MUTATION = 'accountDocumentsFieldsMutation';
export const ADD_TO_IMAGE_STUDIO = 'addImagesToImageStudioMutation';
export const ADD_TO_DERMAID_STUDIO = 'addImagesToDermaidStudioMutation';
export const ADD_PAGES_TO_IMAGE_STUDIO = 'addPagesToImageStudioMutation';
export const ADD_PAGES_TO_DERMAID_STUDIO = 'addPagesToDermaidStudioMutation';
export const CLEAR_IMAGES_MUTATION = 'clearImagesFromImageStudioMutation';
export const REMOVE_IMAGES_FROM_IMAGE_STUDIO = 'removeImagesFromImageStudioMutation';
export const REMOVE_IMAGES_FROM_DERMAID_STUDIO = 'removeImagesFromDermaidStudioMutation';
export const CLEAR_IMAGE_STUDIO_MUTATION = 'clearImageStudioMutation';
export const REMOVE_VIDEO_FROM_IMAGE_STUDIO = 'removeVideoFromImageStudioMutation';
export const SET_LOGIN_REDIRECT_ROUTE = 'setLoginRedirectRouteMutation';
export const CLEAR_LOGIN_REDIRECT_ROUTE = 'clearLoginRedirectRouteMutation';
export const SET_CAPTURES_VIEW = 'setCaptureViewMutation';
export const SET_VIDEO_STUDIO_VIEW = 'setVideoStudioViewMutation';
export const CLEAR_VIDEOS_MUTATION = 'clearVideosFromVideoStudioMutation';
export const ADD_VIDEO_TO_VIDEO_STUDIO = 'addVideoToVideoStudioMutation';
export const SET_PLAYLIST1_VIDEO_STUDIO = 'setVideoToPlaylist1VideoStudioMutation';
export const SET_PLAYLIST2_VIDEO_STUDIO = 'setVideoToPlaylist2VideoStudioMutation';
export const SET_SIDEBYSIDE_VIDEO_STUDIO = 'setVideoToSideBySideVideoStudioMutation';
export const CLEAR_PLAYLISTS_VIDEO_STUDIO = 'setVideoToPlaylistsVideoStudioMutation';
export const CLEAR_SIDEBYSIDE_VIDEO_STUDIO = 'clearSideBySideVideoStudioMutation';
export const SET_UNSELECTED_VIDEOS = 'setUnselectedVideosMutation';
export const BREADCRUMBS_PARAMS_MUTATION = 'breadcrumbsParamsMutation';
export const SET_DERMAID_ENABLED = 'setDermaidEnabledMutation';
export const SET_DERMAID_PRESETS = 'setDermaidPresetsMutation';
export const ORGANIZATION_SETTINGS_MUTATION = 'organizationSettingsMutation';

export default {
  /**
   * Saves information about authorized user.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} user - User's information.
   */
  [USER_MUTATION](state, user) {
    state.auth.user = user;
  },

  /**
   * Saves token for Authorization header.
   *
   * @param {Object} state - Vuex state.
   * @param {string} token - Token returned by login or refresh token request.
   */
  [LOGIN_MUTATION](state) {
    state.auth.authenticated = true;
  },

  /**
   * Clears auth information.
   *
   * @param {Object} state - Vuex state.
   */
  [LOGOUT_MUTATION](state) {
    state.auth.user = null;
    state.auth.authenticated = false;
  },

  /**
   * Save account fields array to storage.
   *
   * @param {Object} state - Vuex state.
   * @param {string} fields - Array of account fields.
   */
  [ACCOUNT_FIELDS_MUTATION](state, fields) {
    state.accountFields = fields;
  },

  /**
   * Updates organization settings.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} settings - Organization settings object.
   */
  [ORGANIZATION_SETTINGS_MUTATION](state, settings) {
    state.organizationSettings = settings;
  },

  /**
   * Save account documents fields array to storage.
   *
   * @param {Object} state - Vuex state.
   * @param {string} fields - Array of account document fields.
   */
  [ACCOUNT_DOCUMENT_FIELDS_MUTATION](state, fields) {
    state.accountDocumentFields = fields;
  },

  /**
   * Add images to image studio.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} images - Array of selected images.
   */
  [ADD_TO_IMAGE_STUDIO](state, images) {
    state.imageStudio.images = _.uniqWith(state.imageStudio.images.concat(images), _.isEqual);
  },

  /**
   * Add images to dermaid studio.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} images - Array of selected images.
   */
  [ADD_TO_DERMAID_STUDIO](state, images) {
    state.dermaidStudio.images = _.uniqWith(state.dermaidStudio.images.concat(images), _.isEqual);
  },

  /**
   * Set page structure in Image Studio
   *
   * @param {Object} state - Vuex state.
   * @param {Array} pages - Array of pages of images
   */
  [ADD_PAGES_TO_IMAGE_STUDIO](state, pages) {
    const imageStudioPages = pages.filter(page => page.pageType !== 'dermaid');

    state.imageStudio.project.pages = imageStudioPages;
  },

  /**
   * Set page structure in Dermaid Studio
   *
   * @param {Object} state - Vuex state.
   * @param {Array} pages - Array of pages of images
   */
  [ADD_PAGES_TO_DERMAID_STUDIO](state, pages) {
    const dermaidPages = pages.filter(page => page.pageType === 'dermaid');

    while (state.dermaidStudio.project.pages.length
      && state.dermaidStudio.project.pages.length > 0) {
      state.dermaidStudio.project.pages.pop();
    }

    state.dermaidStudio.project.pages = dermaidPages;
  },

  /**
   * Remove all images from image studio.
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_IMAGE_STUDIO_MUTATION](state) {
    state.imageStudio = {
      images:  [],
      project: {
        pages: [],
      },
    };
    state.videoStudio = {
      videos:     [],
      view:       null,
      sideBySide: [],
      playlists:  {
        playlist1: [],
        playlist2: [],
      },
    };
  },

  /**
   * Remove all videos from image studio.
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_VIDEOS_MUTATION](state) {
    state.videoStudio.videos = [];
  },

  /**
   * Remove all images from image studio.
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_IMAGES_MUTATION](state) {
    state.imageStudio.images = [];
  },

  /**
   * Remove images from image studio by capture.
   *
   * @param {Object} state - Vuex state.
   * @param {number} id - Capture identifier.
   */
  [REMOVE_IMAGES_FROM_IMAGE_STUDIO](state, image) {
    state.imageStudio.images = state.imageStudio.images
      .filter(img => !(image.frame === img.frame && image.captureId === img.captureId));
  },

  /**
   * Remove images from dermaid studio by capture.
   *
   * @param {Object} state - Vuex state.
   * @param {number} id - Capture identifier.
   */
  [REMOVE_IMAGES_FROM_DERMAID_STUDIO](state, image) {
    state.dermaidStudio.images = state.dermaidStudio.images
      .filter(img => !(image.frame === img.frame && image.captureId === img.captureId));
  },

  /**
   * Sets the Dermaid enabled state.
   *
   * @param {Object} state - Vuex state.
   * @param {boolean} isEnabled - isDermaidEnabled
   * */
  [SET_DERMAID_ENABLED](state, isEnabled) {
    state.isDermaidEnabled = isEnabled;
  },

  /**
   * Add video to video studio.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} video - Video data.
   */
  [ADD_VIDEO_TO_VIDEO_STUDIO](state, video) {
    state.videoStudio.videos.push(video);
    state.videoStudio.videos = _.uniqWith(state.videoStudio.videos, _.isEqual);
  },

  /**
   * Remove video from video studio.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} video - Video data.
   */
  [REMOVE_VIDEO_FROM_IMAGE_STUDIO](state, captureId) {
    state.videoStudio.videos = state.videoStudio.videos
      .filter(el => el.captureId !== captureId);
  },

  /**
   * Set videos to playlist1 in video studio playlist editor
   *
   * @param {Object} state - Vuex state.
   * @param {Object} playlist - list of videos in playlist1
   */
  [SET_PLAYLIST1_VIDEO_STUDIO](state, playlist1) {
    state.videoStudio.playlists.playlist1 = playlist1;
  },

  /**
   * Set videos to playlist1 in video studio playlist editor
   *
   * @param {Object} state - Vuex state.
   * @param {Object} playlist - list of videos in playlist2
   */
  [SET_PLAYLIST2_VIDEO_STUDIO](state, playlist2) {
    state.videoStudio.playlists.playlist2 = playlist2;
  },

  /**
   * Set selected videos in video studio side by side editor
   *
   * @param {Object} state - Vuex state.
   * @param {Object} playlist - list of videos in playlist2
   */
  [SET_SIDEBYSIDE_VIDEO_STUDIO](state, selected) {
    state.videoStudio.sideBySide = selected;
  },

  /**
   *  unactive videos in video studio
   *
   * @param {Object} state - Vuex state.
   * @param {Object} videos - list of videos in Available
   * */
  [SET_UNSELECTED_VIDEOS](state, videos) {
    state.videoStudio.unselectedVideos = videos;
  },

  /**
   * Remove playlists from video studio playlist editor
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_PLAYLISTS_VIDEO_STUDIO](state) {
    state.videoStudio.playlists.playlist1 = [];
    state.videoStudio.playlists.playlist2 = [];
  },

  /**
   * Remove videos from video studio side by side editor
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_SIDEBYSIDE_VIDEO_STUDIO](state) {
    state.videoStudio.sideBySide = [];
  },

  /**
   * Save url of the page before redirect to login page.
   *
   * @param {Object} state - Vuex state.
   * @param {Object|null} route - User url before redirect to login.
   */
  [SET_LOGIN_REDIRECT_ROUTE](state, route) {
    state.auth.referrerRoute = _.cloneDeep(route || state.route);
  },

  /**
   * Clear redirect route.
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_LOGIN_REDIRECT_ROUTE](state) {
    state.auth.referrerRoute = null;
  },

  /**
   * Set view on capture details page.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} view - Capture view.
   */
  [SET_CAPTURES_VIEW](state, view) {
    state.captureView = view;
  },

  /**
   * Set view on video studio page.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} view - Capture view.
   */
  [SET_VIDEO_STUDIO_VIEW](state, view) {
    state.videoStudio.view = view;
  },

  /**
   * Save breadcrumbs params.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} params - Breadcrumbs params.
   */
  [BREADCRUMBS_PARAMS_MUTATION](state, params) {
    state.breadcrumbs = params;
  },

  /**
   * Set Dermaid presets.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} presets - Dermaid presets.
   */
  [SET_DERMAID_PRESETS](state, presets) {
    state.dermaidPresets = presets;
  },

};
